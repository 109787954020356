import logo from './image.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          OnWatts
        </p>
        <img src={logo} alt="logo" />
      </header>
    </div>
  );


}
export default App;
